import Inputmask from "inputmask";
const $ = require('jquery');

// $(document).ready(function(){
//   $('.reviews__slider').slick({
//     slidesToShow: 3,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 2000,
//   });
// });

window.openTab = function(i) {
  document.querySelector(`.hidden_block-${i}`).classList.toggle('show');
  document.querySelector(`.subtitle_${i} svg`).classList.toggle('rotate');
}

$("#feedback-1").submit(function(e){
    e.preventDefault();
    $.ajax({
        type: "POST",
        url: "wdh_send_form.php",
        data: $("#feedback-1").serialize(),
        success: function(data) {
            $("#wdh_result_form-1").html(data);
            $("#feedback-1").trigger("reset");
            setTimeout(() => {$("#wdh_result_form-1").html('')}, 3000);
        }
    });
});
$("#feedback-2").submit(function(e){
    e.preventDefault();
    $.ajax({
        type: "POST",
        url: "wdh_send_form.php",
        data: $("#feedback-2").serialize(),
        success: function(data) {
            $("#wdh_result_form-2").html(data);
            $("#feedback-2").trigger("reset");
            setTimeout(() => {$("#wdh_result_form-2").html('')}, 3000);
        }
    });
});

Inputmask({"mask": "+7 (999) 999-9999"}).mask('#phone-1');
Inputmask({"mask": "+7 (999) 999-9999"}).mask('#phone-2');
